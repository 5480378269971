import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { ApplyTravelFormFilter } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import dynamic from 'next/dynamic';

import TravelFormActivityOverlayContainer from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay-container';
import TravelFormOverlayWrapper from '@/features/travel-form/travel-form-overlay/travel-form-overlay-wrapper/travel-form-overlay-wrapper';

const TravelFormDatePickerOverlayContainer = dynamic(
    () =>
        import(
            /* webpackChunkName: "TravelFormDatePickerOverlayContainer" */ '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/travel-form-date-picker-overlay-container'
        ),
);

const TravelFormDestinationOverlayContainer = dynamic(
    () =>
        import(
            /* webpackChunkName: "TravelFormDestinationOverlayContainer" */ '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-container'
        ),
);

const TravelFormDurationOverlayContainer = dynamic(
    () =>
        import(
            /* webpackChunkName: "TravelFormDurationOverlayContainer" */ '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/travel-form-duration-overlay-container'
        ),
);

const TravelFormAirportOverlayContainer = dynamic(
    () =>
        import(
            /* webpackChunkName: "TravelFormAirportOverlayContainer" */ '@/features/travel-form/travel-form-overlay/travel-form-airport-overlay/travel-form-airport-overlay-container'
        ),
);

const TravelFormRoomAllocationOverlayContainer = dynamic(
    () =>
        import(
            /* webpackChunkName: "TravelFormRoomAllocationOverlayContainer" */ '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-overlay-container'
        ),
);

type TravelFormOverlaySwitchProps = {
    activityRequestTarget: string | undefined;
    airportsRequestTarget: string | undefined;
    dateRequestTarget: string | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    onClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    overlayType: TravelFormOverlayName | null;
    travelForm: TravelFormDataV1Attributes | null;
};

export default function TravelFormOverlaySwitch({
    activityRequestTarget,
    airportsRequestTarget,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    onClose,
    onOverlayCtaClick,
    overlayType,
    travelForm,
}: TravelFormOverlaySwitchProps) {
    if (!overlayType) {
        return null;
    }

    if (!travelForm) {
        return (
            <TravelFormOverlayWrapper
                isCtaHidden={true}
                isLoading={true}
                onClose={() => {}}
                title={' '}
            />
        );
    }

    const { filters } = travelForm;
    const { activity, airports, date, destination, duration, roomAllocation } = filters;

    switch (overlayType) {
        case 'activity':
            return (
                activityRequestTarget && (
                    <TravelFormActivityOverlayContainer
                        activity={activity}
                        activityRequestTarget={activityRequestTarget}
                        onClose={onClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )
            );
        case 'destination':
            return (
                destinationChangeRequestTarget &&
                destinationRequestTarget && (
                    <TravelFormDestinationOverlayContainer
                        destination={destination}
                        destinationChangeRequestTarget={destinationChangeRequestTarget}
                        destinationRequestTarget={destinationRequestTarget}
                        onClose={onClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )
            );
        case 'airports':
            return (
                airportsRequestTarget && (
                    <TravelFormAirportOverlayContainer
                        airports={airports}
                        airportsRequestTarget={airportsRequestTarget}
                        onClose={onClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )
            );
        case 'date':
            return (
                dateRequestTarget && (
                    <TravelFormDatePickerOverlayContainer
                        date={date}
                        dateRequestTarget={dateRequestTarget}
                        onClose={onClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )
            );
        case 'duration':
            return (
                <TravelFormDurationOverlayContainer
                    duration={duration}
                    onClose={onClose}
                    onOverlayCtaClick={onOverlayCtaClick}
                />
            );
        case 'roomAllocation':
            return (
                <TravelFormRoomAllocationOverlayContainer
                    onClose={onClose}
                    onOverlayCtaClick={onOverlayCtaClick}
                    roomAllocation={roomAllocation}
                />
            );
        default:
            return null;
    }
}
