import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { ApplyTravelFormFilter } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormField, TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import TravelFormExpanded from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import TravelFormOverlaySwitch from '@/features/travel-form/travel-form-overlay/travel-form-overlay-switch/travel-form-overlay-switch';

export type TravelFormProps = {
    activityRequestTarget: string | undefined;
    airportsRequestTarget: string | undefined;
    dateRequestTarget: string | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    formConfiguration: {
        activeFilters: TravelFormDataV1Attributes['activeFilters'];
        cta: {
            dependsOn: TravelFormFilterName[];
            text: string;
        };
        fields: Record<keyof TravelFormDataV1Attributes['filters'], TravelFormField | null>;
        isLoading: boolean;
        noPadding?: boolean;
        onFormLoaded?: () => void;
        onSubmit: () => void;
        openOverlay: (overlayName: TravelFormOverlayName) => void;
    };
    noBackground?: boolean;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    overlayType: TravelFormOverlayName | null;
    travelForm: TravelFormDataV1Attributes | null;
};

export default function TravelForm({
    activityRequestTarget,
    airportsRequestTarget,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    formConfiguration,
    noBackground,
    onOverlayClose,
    onOverlayCtaClick,
    overlayType,
    travelForm,
}: TravelFormProps) {
    return (
        <>
            <TravelFormOverlaySwitch
                activityRequestTarget={activityRequestTarget}
                airportsRequestTarget={airportsRequestTarget}
                dateRequestTarget={dateRequestTarget}
                destinationChangeRequestTarget={destinationChangeRequestTarget}
                destinationRequestTarget={destinationRequestTarget}
                onClose={onOverlayClose}
                onOverlayCtaClick={onOverlayCtaClick}
                overlayType={overlayType}
                travelForm={travelForm}
            />
            <TravelFormExpanded
                activeFilters={formConfiguration.activeFilters}
                activity={formConfiguration.fields.activity}
                airports={formConfiguration.fields.airports}
                cta={formConfiguration.cta}
                date={formConfiguration.fields.date}
                destination={formConfiguration.fields.destination}
                duration={formConfiguration.fields.duration}
                isLoading={formConfiguration.isLoading}
                noBackground={noBackground}
                noPadding={formConfiguration.noPadding}
                onSubmit={formConfiguration.onSubmit}
                onTravelFormLoaded={formConfiguration.onFormLoaded}
                openOverlay={formConfiguration.openOverlay}
                overlayType={overlayType}
                roomAllocation={formConfiguration.fields.roomAllocation}
            />
        </>
    );
}
