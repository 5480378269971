import { IconImage, LoadingSpinnerIcon } from '@/core/features';
import color from '@/core/features/styles/color';
import { bemModule, classnames } from '@/core/utils/bem-classname';

import styles from './travel-form-input.module.scss';

const bem = bemModule(styles);

type TravelFormInputProps = {
    error?: {
        iconUrl: string;
        text: string;
    } | null;
    iconUrl?: null | string;
    isLoading: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    qaId?: string;
    value: string;
};

export default function TravelFormInput({ error, iconUrl, isLoading, onClick, qaId, value }: TravelFormInputProps) {
    const hasError = !!error;

    return (
        <div
            className={styles.travelFormInput}
            data-qa-id={qaId}
            onClick={onClick}
        >
            {iconUrl && (
                <IconImage
                    className={classnames('shrink-0', { 'icon-color-default': !hasError })}
                    height={15}
                    url={hasError ? error.iconUrl : iconUrl}
                    width={15}
                />
            )}
            <div className={'flex-space-between-horizontal width-100'}>
                <div className={`line-clamp-1 ${bem(styles.travelFormValue, { hasError })}`}>
                    {hasError ? error?.text : value}
                </div>
                {isLoading && (
                    <LoadingSpinnerIcon
                        color={color('endeavour')}
                        height={16}
                        width={16}
                    />
                )}
            </div>
        </div>
    );
}
